// Theme options
const neonGreen = '#39ff14';
const neonBlue = '#00F9FF';
const brightPink = '#e502fa';

// Determine the theme color based on the URL hash
let themeColor = neonBlue; // default to neonBlue
const hash = window.location.hash;
if (hash) {
  if (hash === '#green') {
    themeColor = neonGreen;
  } else if (hash === '#pink') {
    themeColor = brightPink;
  } // If hash is not green or pink, default to blue (already set)
}

// Other colors
const offWhiteToRed = '#fae9e9';

const lightModeStyles = {
  groundColor: themeColor,
  backgroundAndFog: themeColor,
  defaultTextColor: 'black',
  themeColor: 'black',
  projectButtonBorder: '2.4px solid var(--theme-color)',
  sparklesColor: "#000"
};

const darkModeStyles = {
  groundColor: 0x000000,
  backgroundAndFog: 0x000000,
  defaultTextColor: offWhiteToRed,
  themeColor: themeColor,
  projectButtonBorder: '1.4px solid var(--theme-color)',
  sparklesColor: themeColor
};

export { lightModeStyles, darkModeStyles };