import * as THREE from 'three';
import { useAnimations, useGLTF } from '@react-three/drei';
import { useThree, useFrame } from '@react-three/fiber';
import { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';

//
// Local imports
//
import useStore from '../store/store';

const modelPath = './models/Cybertruck.glb';

export default function Cybertruck() {
  //
  // Global states
  //
  const startButtonPressed = useStore(state => state.startButtonPressed);
  const setMyInnerHtmlVisible = useStore(state => state.setMyInnerHtmlVisible);
  const cybertruckDrivingAnimationComplete = useStore(state => state.cybertruckDrivingAnimationComplete);
  const setCybertruckDrivingAnimationComplete = useStore(state => state.setCybertruckDrivingAnimationComplete);
  const projecSectionActive = useStore(state => state.projectSectionActive);

  // camera global state
  const orbitControlsEnabled = useStore(state => state.orbitControlsEnabled);
  const setOrbitControlsEnabled = useStore(state => state.setOrbitControlsEnabled);
  const cameraFollowsCybertruck = useStore(state => state.cameraFollowsCybertruck);
  const setCameraFollowsCybertruck = useStore(state => state.setCameraFollowsCybertruck);
  const cameraLooksAtCybertruck = useStore(state => state.cameraLooksAtCybertruck);
  const setCameraLooksAtCybertruck = useStore(state => state.setCameraLooksAtCybertruck);
  const mouseEffectsCamera = useStore(state => state.mouseEffectsCamera);
  const setMouseEffectsCamera = useStore(state => state.setMouseEffectsCamera);

  //
  // Local constants
  //
  const defaultLerpFactorCameraPosition = 0.02;
  const defaultLerpFactorCameraLookAt = 0.01;
  const lerpFactorCameraPositionWhenFollowingCybertruck = 0.15;
  const lerpFactorCameraLookAtWhenFollowingCybertruck = 0.15;
  const defaultSensitiveHorizontal = 0.4
  const defaultSensitiveVertical = 0.2
  const cameraPositionHomeSection = new THREE.Vector3(3.6, 1.3, 4.4);
  const cameraLookAtTargetHomeSection = new THREE.Vector3(0, 0, 0);
  const cameraPositionProjectSection = new THREE.Vector3(2, 7, 0.4);
  const cameraLookAtTargetProjectSection = new THREE.Vector3(2.03, 0, 1.4);
  //
  // Refs
  // 
  const model = useGLTF(modelPath);
  const animations = useAnimations(model.animations, model.scene);
  const { camera } = useThree();
  const cybertruckRef = useRef({
    parent: null,
    body: null,
  });
  const pointLightRef = useRef();

  //
  // Local state
  //
  const [finishedButNotStoppedAction, setFinishedButNotStoppedAction] = useState(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  // This decides if the camera POSITION is affected by useFrame
  const [cameraPositionEffectedByUseFrame, setCameraPositionEffectedByUseFrame] = useState(true);
  // These 3 should never be set directly, only through the useFrame function
  const [cameraPositionTarget, setCameraPositionTarget] = useState(new THREE.Vector3());
  const [cameraLookAtCurrent, setCameraLookAtCurrent] = useState(new THREE.Vector3());
  const [cameraLookAtTarget, setCameraLookAtTarget] = useState(new THREE.Vector3());
  // This decides the camera offset when following the cybertruck
  const [cameraOffsetWhenFollowingCybertruck, setCameraOffsetWhenFollowingCybertruck] = useState(new THREE.Vector3(4, 0.6, -5));
  // These decide the lerp factor for the camera position and look at at all times
  const [lerpFactorCameraPosition, setLerpFactorCameraPosition] = useState(lerpFactorCameraPositionWhenFollowingCybertruck);
  const [lerpFactorCameraLookAt, setLerpFactorCameraLookAt] = useState(lerpFactorCameraLookAtWhenFollowingCybertruck);
  // These decide the mouse effect sensitivity
  const [sensitivityHorizontal, setSensitivityHorizontal] = useState(0);
  const [sensitivityVertical, setSensitivityVertical] = useState(0);
  // This decides the look at adjustment when looking at the cybertruck
  const [lookAtAdjustment, setLookAtAdjustment] = useState(new THREE.Vector3(-0.1, 0.26, 0.1));
  // These decide the camera position and look at when not following the cybertruck
  const [cameraPositionNotFollowingCybertruck, setCameraPositionNotFollowingCybertruck] = useState(cameraPositionHomeSection);
  const [cameraLookAtTargetNotFollowingCybertruck, setCameraLookAtTargetNotFollowingCybertruck] = useState(cameraLookAtTargetHomeSection);

  //
  // On mount -> Set up the scene
  //
  useEffect(() => {
    const cybertruckParent = model.scene.getObjectByName('car-Parent');
    const cybertruckBody = model.scene.getObjectByName('car-Body');
    if (cybertruckBody && cybertruckParent) {
      cybertruckRef.current.parent = cybertruckParent;
      cybertruckRef.current.body = cybertruckBody;
    } else {
      console.log('Cybertruck body or parent not found');
    }
  }, []);

  //
  // On mount -> Set up mousemove event listener
  //
  useEffect(() => {
    const handleMouseMove = (event) => {
      const x = (event.clientX / window.innerWidth) * 2 - 1;
      const y = -(event.clientY / window.innerHeight) * 2 + 1;
      setMousePosition({ x, y });
    };
    window.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  //
  // On startButtonPressed -> Play the intro animations
  //
  useEffect(() => {
    if (startButtonPressed) {
      setCameraFollowsCybertruck(true);
      setCameraLooksAtCybertruck(true);

      const cybertruckDrivingActionName = "Cyber Action 1";
      const cybertruckDrivingAction = animations.actions[cybertruckDrivingActionName];

      if (!cybertruckDrivingAction) {
        console.log(`Animation "${cybertruckDrivingActionName}" not found.`);
      } else {
        new Promise((resolveCybertruck) => {
          cybertruckDrivingAction.clampWhenFinished = true;
          cybertruckDrivingAction.setLoop(THREE.LoopOnce);
          cybertruckDrivingAction.play();
          cybertruckDrivingAction.getMixer().addEventListener('finished', () => resolveCybertruck());
          setTimeout(() => {
            setCameraPositionEffectedByUseFrame(false);
            setCameraFollowsCybertruck(false);
            gsap.to(camera.position, {
              x: cameraPositionNotFollowingCybertruck.x,
              y: cameraPositionNotFollowingCybertruck.y,
              z: cameraPositionNotFollowingCybertruck.z,
              duration: 3.1,
              ease: 'power3.inOut',
              onComplete: () => {
                setLerpFactorCameraPosition(defaultLerpFactorCameraPosition);
                setLerpFactorCameraLookAt(defaultLerpFactorCameraLookAt);
                setCameraPositionEffectedByUseFrame(true);
              }
            });
          }, 2900);
        }).then(() => {
          setCybertruckDrivingAnimationComplete(true);
          cybertruckDrivingAction.stop();
          const teslaBotAllMergedToCybertruck = model.scene.getObjectByName('TeslaBotAllMergedToCybertruck');
          if (teslaBotAllMergedToCybertruck) {
            teslaBotAllMergedToCybertruck.scale.set(0, 0, 0);
          }
          const cyberOpenDoorActionName = "CyberOpenDoor";
          const cyberOpenDoorAction = animations.actions[cyberOpenDoorActionName];
          if (cyberOpenDoorAction) {
            cyberOpenDoorAction.clampWhenFinished = true;
            cyberOpenDoorAction.setLoop(THREE.LoopOnce);
            cyberOpenDoorAction.play();
            cyberOpenDoorAction.getMixer().addEventListener('finished', () => {
              setFinishedButNotStoppedAction(cyberOpenDoorAction);
            });
          }
          setMyInnerHtmlVisible(true);
          setMouseEffectsCamera(true);
          changeMouseEffectSensitivity(2000, defaultSensitiveHorizontal, defaultSensitiveVertical);
        });
      }
    }
  }, [startButtonPressed]);

  //
  // On projectSectionActive -> Set the camera position and look at based on the section
  //
  useEffect(() => {
    if (projecSectionActive) {
      setLerpFactorCameraLookAt(defaultLerpFactorCameraLookAt * 3);
      setLerpFactorCameraPosition(defaultLerpFactorCameraPosition * 2);
      setCameraLooksAtCybertruck(false);
      setMouseEffectsCamera(false);
      setCameraPositionNotFollowingCybertruck(cameraPositionProjectSection);
      setCameraLookAtTargetNotFollowingCybertruck(cameraLookAtTargetProjectSection);

    } else {
      if (cybertruckDrivingAnimationComplete) {
        setLerpFactorCameraLookAt(defaultLerpFactorCameraLookAt * 1);
        setLerpFactorCameraPosition(defaultLerpFactorCameraPosition / 1.4);
      } else {
        setLerpFactorCameraLookAt(lerpFactorCameraLookAtWhenFollowingCybertruck);
        setLerpFactorCameraPosition(lerpFactorCameraPositionWhenFollowingCybertruck);
      }
      setCameraLooksAtCybertruck(true);
      setMouseEffectsCamera(true);
      setCameraPositionNotFollowingCybertruck(cameraPositionHomeSection);
      setCameraLookAtTargetNotFollowingCybertruck(cameraLookAtTargetHomeSection);
    }
  }, [projecSectionActive]);

  //
  // Functions
  //
  const changeMouseEffectSensitivity = (duration, targetHorizontal, targetVertical) => {
    const startTime = Date.now();
    const initialHorizontal = sensitivityHorizontal;
    const initialVertical = sensitivityVertical;
    const updateSensitivity = () => {
      const elapsedTime = Date.now() - startTime;
      const fraction = Math.min(elapsedTime / duration, 1);
      const newHorizontal = initialHorizontal + (targetHorizontal - initialHorizontal) * fraction;
      const newVertical = initialVertical + (targetVertical - initialVertical) * fraction;
      setSensitivityHorizontal(newHorizontal);
      setSensitivityVertical(newVertical);
      if (fraction < 1) {
        requestAnimationFrame(updateSensitivity);
      }
    };
    requestAnimationFrame(updateSensitivity);
  };

  //
  // On useFrame -> Update the camera position and look at based on camera states
  //
  useFrame((state, delta) => {

    const deltaFactor = delta * 100;
    const { body } = cybertruckRef.current;

    if (body) {
      const cybertruckBodyPosition = new THREE.Vector3();
      body.getWorldPosition(cybertruckBodyPosition);
      let mouseEffectVector = new THREE.Vector3();

      // Update the point light position to follow the cybertruck
      if (pointLightRef.current) {
        let directionVector = new THREE.Vector3().subVectors(camera.position, cybertruckBodyPosition).normalize();
        let distanceBehindCybertruck = 12;
        let heightAboveGround = 10;
        let pointLightPosition = new THREE.Vector3().addVectors(cybertruckBodyPosition, directionVector.multiplyScalar(-distanceBehindCybertruck));
        pointLightPosition.y = heightAboveGround;
        pointLightRef.current.position.copy(pointLightPosition);
      }

      if (orbitControlsEnabled) {
        return;
      }

      // Set the camera position target state based on if we are following the cybertruck
      if (cameraFollowsCybertruck) {
        setCameraPositionTarget(cybertruckBodyPosition.clone().add(cameraOffsetWhenFollowingCybertruck));
      } else {
        setCameraPositionTarget(cameraPositionNotFollowingCybertruck);
      }

      // Set the camera look at target state based on if we are looking at the cybertruck
      if (cameraLooksAtCybertruck) {
        setCameraLookAtTarget(cybertruckBodyPosition.clone().add(lookAtAdjustment));
      } else {
        setCameraLookAtTarget(cameraLookAtTargetNotFollowingCybertruck);
      }

      // Calculate the mouse effect vector to add
      if (mouseEffectsCamera) {
        const forwardVector = new THREE.Vector3().subVectors(cameraLookAtCurrent, camera.position).normalize();
        const upVector = new THREE.Vector3(0, 1, 0);
        const rightVector = new THREE.Vector3().crossVectors(upVector, forwardVector).normalize();
        const horizontalEffect = rightVector.multiplyScalar(mousePosition.x * sensitivityHorizontal);
        const verticalEffect = upVector.multiplyScalar(-mousePosition.y * sensitivityVertical);
        mouseEffectVector = horizontalEffect.add(verticalEffect);
      }

      // Update the camera position if it's affected by useFrame
      if (cameraPositionEffectedByUseFrame) {
        camera.position.lerp(cameraPositionTarget.clone().add(mouseEffectVector), lerpFactorCameraPosition * deltaFactor);
      }
      // Update the camera look at
      setCameraLookAtCurrent(cameraLookAtCurrent.lerp(cameraLookAtTarget, lerpFactorCameraLookAt * deltaFactor));
      camera.lookAt(cameraLookAtCurrent);
    }
  });

  return <group>
    <primitive object={model.scene} />
    {/* This light follows the cybertruck */}
    <pointLight
      ref={pointLightRef}
      color={[0.8, 1, 0.8]}
      intensity={25}
    />
  </group>;
}