import './style.css'
import { Canvas } from '@react-three/fiber'
import { Suspense, useEffect } from 'react'
import { Leva } from 'leva'
import { useProgress } from '@react-three/drei';
import { Analytics } from "@vercel/analytics/react"

// Local imports
import MyHtml from './components/html/MyHtml.jsx'
import Experience from './Experience.jsx'
import useStore from './store/store.js'

export default function App() {
  //
  // Global states
  //
  const setDebugMode = useStore(state => state.setDebugMode);
  const { progress } = useProgress();
  const setLoadingProgress = useStore(state => state.setLoadingProgress);

  //
  // On mount
  //
  useEffect(() => {

    // Set debug mode
    const handleHashChange = () => {
      setDebugMode(window.location.hash === '#debug');
    };
    window.addEventListener('hashchange', handleHashChange);
    handleHashChange();

    // Cleanup
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  //
  // On progress
  //
  useEffect(() => {
    setLoadingProgress(progress);
  }, [progress, setLoadingProgress]);


  return <>
    <Analytics />

    <div className="leva-container">
      <Leva collapsed={true} fill={true} />
    </div>

    <MyHtml />
    <Canvas
      className="r3f"
    >
      <Suspense fallback={null}>
        <Experience />
      </Suspense>
    </Canvas>
  </>
}