import React, { useEffect } from "react";
import { useLoader } from "@react-three/fiber";
import { LinearSRGBColorSpace, RepeatWrapping, TextureLoader } from "three";

// Local imports
import { lightModeStyles, darkModeStyles } from '../config.js';
import useStore from '../store/store.js'

export default function Ground() {

  // Global states
  const lightMode = useStore(state => state.lightMode)

  // Load the textures
  const [roughnessMap, normalMap] = useLoader(TextureLoader, [
    "/textures/comp-terrain-roughness-1024.jpg",
    "/textures/comp-terrain-normal-1024.jpg",
  ]);

  //
  // On mount -> Set the texture properties
  //
  useEffect(() => {
    [normalMap, roughnessMap].forEach((texture) => {
      texture.wrapS = RepeatWrapping;
      texture.wrapT = RepeatWrapping;
      texture.repeat.set(44, 44);
      texture.offset.set(0, 0);
    });

    normalMap.colorSpace = LinearSRGBColorSpace;
  }, [normalMap, roughnessMap]);

  //
  // On light mode change -> Update the ground material
  //

  return (
    <mesh rotation-x={-Math.PI * 0.5} castShadow receiveShadow position={[0, -0.04, 0]}>
      <planeGeometry args={[100, 100]} />
      <meshStandardMaterial
        // Light blue
        color={lightMode ? lightModeStyles.groundColor : darkModeStyles.groundColor}
        roughness={0.6}
        metalness={0.5}
        normalMap={normalMap}
        normalScale={[1, 1]}
        roughnessMap={roughnessMap}
        envMapIntensity={0}
      />
      {/* <meshStandardMaterial color="#FF0000" /> */}
    </mesh>
  );
};