import React, { useState, useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { FaLinkedinIn } from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";
import { PiGithubLogoFill } from "react-icons/pi";
import { RiVolumeMuteFill, RiVolumeUpFill } from "react-icons/ri";
import { MdOutlineContactPage } from "react-icons/md";
import { MdLightMode } from "react-icons/md";
import { MdDarkMode } from "react-icons/md";
import { FaArrowLeftLong } from "react-icons/fa6";
import { FaArrowRightLong } from "react-icons/fa6";
import { FaCode } from "react-icons/fa6";

// Local imports
import './MyHtml.css';
import useStore from '../../store/store.js';

// Sounds

const mergedIntroSound = new Audio('sounds/merged-intro-sound.mp3');
const clickSound = new Audio('sounds/click.mp3');
const wooshSound = new Audio('sounds/woosh.mp3');
const danceSongSound = new Audio('sounds/dance-song.mp3');

const MyHtml = () => {
  // Global state
  const loadingProgress = useStore(state => state.loadingProgress)
  const startButtonPressed = useStore(state => state.startButtonPressed)
  const setStartButtonPressed = useStore(state => state.setStartButtonPressed)
  const myInnerHtmlVisible = useStore(state => state.myInnerHtmlVisible)
  const useMergedIntroSound = useStore(state => state.useMergedIntroSound)
  const isMuted = useStore(state => state.muted)
  const setIsMuted = useStore(state => state.setMuted)
  const projectSectionActive = useStore(state => state.projectSectionActive)
  const setProjectSectionActive = useStore(state => state.setProjectSectionActive)
  const lightMode = useStore(state => state.lightMode)
  const setLightMode = useStore(state => state.setLightMode)
  const dancing = useStore(state => state.dancing)
  const setDancing = useStore(state => state.setDancing)

  // Local state
  const [loadingScreenVisible, setLoadingScreenVisible] = useState(true);
  const [muteButtonVisable, setMuteButtonVisable] = useState(false);
  const [lightModeButtonVisible, setLightModeButtonVisible] = useState(false);
  const [activeProject, setActiveProject] = useState(0);

  // Refs
  const loadingScreenRef = useRef(null);
  const startButtonRef = useRef(null);
  const loadingScreenImageRef = useRef(null);

  // Constants
  const projects = [
    {
      title: '3D Driving AI',
      description: 'Interactive 3D web app that combines Proximal Policy Optimization with Three.js, enabling users to directly interact with or train AI models on a virtual racetrack.',
      imageSrc: 'images/3d-driving-ai-compressed.png',
      codeLink: 'https://github.com/fjcollyer/3D-Driving-AI-PPO',
      websiteLink: 'https://3d-driving-ai.vercel.app/'
    },
    {
      title: 'Connect 4 AI',
      description: 'A deep Q-learning AI model trained with TensorFlow powers a 3D Connect 4 web application.',
      imageSrc: 'images/connect4-ai-compressed.png',
      codeLink: 'https://github.com/fjcollyer/Connect4-AI-Deep-Q-Learning',
      websiteLink: 'https://connect4-ai-liard.vercel.app/'
    },
    {
      title: 'Personal Website',
      description: 'You are here! This website was built using a combination of Blender for 3D modeling and React Three Fiber for rendering.',
      imageSrc: 'images/personal-website-compressed.png',
      codeLink: '',
      websiteLink: ''
    }
  ];

  // 
  // Functions
  //
  const toggleMute = () => {
    if (!isMuted) {
      mergedIntroSound.muted = true;
      danceSongSound.muted = true;
      clickSound.muted = true;
      wooshSound.muted = true;
    } else {
      mergedIntroSound.muted = false;
      danceSongSound.muted = false;
      clickSound.muted = false;
      wooshSound.muted = false;
    }

    setIsMuted(!isMuted);
  };

  //
  // On mount 
  //
  useEffect(() => {
    // NOTE: This only works because the sound takes longer than the dancing animation
    danceSongSound.onended = () => {
      setDancing(false);
    };
  }, []);

  //
  // On loading progress -> Update the start button text and interaction, 1s after reaching 100% to ensure proper loading of the sceene
  //
  useEffect(() => {
    const progressRounded = Math.round(loadingProgress);
    if (startButtonRef.current) {
      if (progressRounded < 100) {
        startButtonRef.current.textContent = `${progressRounded}%`;
        startButtonRef.current.style.pointerEvents = 'none';
      } else {
        startButtonRef.current.textContent = '99%';
        startButtonRef.current.style.pointerEvents = 'none';
        setTimeout(() => {
          startButtonRef.current.textContent = 'Start';
          startButtonRef.current.style.pointerEvents = 'auto';
        }, 1000);
        gsap.to('.spinner', {
          opacity: 0,
          duration: 1,
          delay: 0.5
        });
      }
    }
  }, [loadingProgress]);

  //
  // On myInnerHtmlVisible and change of project state -> Adjust the css
  //
  useEffect(() => {
    if (!myInnerHtmlVisible) {
      return;
    }

    const adjustImageWidths = () => {
      const textWidth = document.getElementById('myName')?.offsetWidth || 0;

      const images = document.querySelectorAll('.projectImage');
      const projectDescriptions = document.querySelectorAll('.projectDescription');
      const projectLinks = document.querySelectorAll('.projectLinks');
      const projectNavButtons = document.querySelectorAll('.projectNavButtons');

      if (images.length) {
        images.forEach(image => {
          image.style.width = `${textWidth}px`;
          image.style.height = `${textWidth / 2}px`;
        });
      }

      let maxHeight = 0;
      if (projectDescriptions.length) {
        projectDescriptions.forEach(desc => {
          desc.style.width = `${textWidth}px`;
          if (desc.offsetHeight > maxHeight) {
            maxHeight = desc.offsetHeight;
          }
        });
        projectDescriptions.forEach(desc => {
          if (desc.offsetHeight < maxHeight) {
            desc.style.height = `${maxHeight}px`;
          }
        });
      }

      if (projectLinks.length) {
        projectLinks.forEach(link => {
          link.style.width = `${textWidth}px`;
        });
      }

      if (projectNavButtons.length) {
        projectNavButtons.forEach(navButton => {
          navButton.style.width = `${textWidth}px`;
        });
      }
    };

    adjustImageWidths();
    window.addEventListener('resize', adjustImageWidths);

    return () => window.removeEventListener('resize', adjustImageWidths);
  }, [myInnerHtmlVisible, projectSectionActive, activeProject]);


  return (
    <div className="myhtmlOuter">

      {loadingScreenVisible && (
        <div ref={loadingScreenRef} className="loadingScreen">
          <div className="spinner"></div>
          <div className="loadingScreenInner">
            <img ref={loadingScreenImageRef} src="images/blue-lightning.png" alt="Blue Lightning" className="loadingScreenImage" />
            <button
              ref={startButtonRef}
              className="startButton"
              onClick={() => {
                if (startButtonPressed) return;
                setStartButtonPressed(true);
                clickSound.play().catch(error => console.error("Error playing sound:", error));
                if (useMergedIntroSound) {
                  mergedIntroSound.play().catch(error => console.error("Error playing sound:", error));
                }
                setMuteButtonVisable(true);
                setLightModeButtonVisible(true);

                const tl = gsap.timeline({
                  onComplete: () => setLoadingScreenVisible(false)
                });

                startButtonRef.current.style.cursor = 'default';
                tl.to([startButtonRef.current, loadingScreenImageRef.current], {
                  opacity: 0,
                  duration: 0.5,
                })
                  .to(loadingScreenRef.current, {
                    opacity: 0,
                    duration: 1.3,
                  });
              }}>
            </button>
          </div>
        </div>
      )}

      {muteButtonVisable && (
        <>
          <div className="muteButtonContainer">
            <button className="muteButton" onClick={toggleMute}>
              {isMuted ? <RiVolumeMuteFill className='mute-icon' /> : <RiVolumeUpFill className='mute-icon' />}
            </button>
          </div>

          <img src='images/blue-lightning.png' alt='FC Logo' className='topLeftLogo' />

          {/* Dance button */}
          <button className="danceButton"
            style={{
              pointerEvents: dancing ? 'none' : 'auto',
              opacity: dancing ? 0.6 : 1
            }}
            onClick={() => {
              setDancing(true);
              if (window.innerWidth > 600) {
                clickSound.currentTime = 0;
                clickSound.play().catch(error => console.error("Error playing sound:", error));
                danceSongSound.currentTime = 0;
                danceSongSound.play().catch(error => console.error("Error playing sound:", error));
              } else {
                clickSound.play().catch(error => console.error("Error playing sound:", error));
                danceSongSound.play().catch(error => console.error("Error playing sound:", error));
              }
            }}
          >
            {!dancing ? 'Dance' : ''}
            {dancing && <div className="bars">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>}
          </button>
        </>
      )}

      {lightModeButtonVisible && (
        <div className="lightModeButtonContainer">
          <button className="lightModeButton" onClick={() => {
            if (!isMuted && (window.innerWidth > 600)) {
              clickSound.currentTime = 0;
              clickSound.play().catch(error => console.error("Error playing sound:", error));
            } else if (!isMuted) {
              clickSound.play().catch(error => console.error("Error playing sound:", error));
            }
            setLightMode(!lightMode);
          }}>
            {lightMode ? <MdLightMode className='lightMode-icon' /> : <MdDarkMode className='lightMode-icon' />}
          </button>
        </div>
      )}


      {myInnerHtmlVisible && (
        <div className='myhtmlInner'>
          {/* Home section */}
          <div className="homeContainer">
            <h1 className="myName" id='myName'>Fredrik Collyer</h1>
            <div className="socialsAndProjectContainer">
              <div className="socialsContainer">
                <a href="https://twitter.com/fjcollyer" target="_blank" rel="noreferrer">
                  <FaXTwitter className='social-icon' />
                </a>
                <a href="https://github.com/fjcollyer" target="_blank" rel="noreferrer">
                  <PiGithubLogoFill className='social-icon' />
                </a>
                <a href="https://www.linkedin.com/in/fjcollyer/" target="_blank" rel="noreferrer">
                  <FaLinkedinIn className='social-icon' />
                </a>
                <a href="https://drive.google.com/file/d/1emkbjXhi07sO1mbpMDhvhbvFxdzLCcUD/view?usp=sharing" target="_blank" rel="noreferrer">
                  <MdOutlineContactPage className='social-icon' />
                </a>
              </div>
              <button className="projectButton" onClick={() => {
                if (!isMuted && (window.innerWidth > 600)) {
                  clickSound.currentTime = 0;
                  clickSound.play().catch(error => console.error("Error playing sound:", error));
                  wooshSound.currentTime = 0;
                  wooshSound.play().catch(error => console.error("Error playing sound:", error));
                } else if (!isMuted) {
                  clickSound.play().catch(error => console.error("Error playing sound:", error));
                }

                setProjectSectionActive(!projectSectionActive);
              }}>
                {projectSectionActive ? 'Back' : 'Projects'}
              </button>
            </div>

            {/* Projects section */}
            <div className="projectSectionContainer" style={{ opacity: projectSectionActive ? 1 : 0, pointerEvents: projectSectionActive ? 'auto' : 'none' }}>
              <div className="projectSection">
                {projects.map((project, index) => (
                  <div key={index} className="projectCard" style={{ opacity: activeProject === index ? 1 : 0, pointerEvents: activeProject === index ? 'auto' : 'none' }}>
                    <img src={project.imageSrc} alt={project.title} className='projectImage' />
                    <h3 className='projectTitle'>{project.title}</h3>
                    <p className='projectDescription'>{project.description}</p>
                    <div className="projectLinks">
                      <div className="projectCodeLinkButtonOuter">
                        <div className="projectCodeLinkButton"
                          style={{
                            pointerEvents: activeProject === index && project.websiteLink && projectSectionActive ? 'auto' : 'none',
                            opacity: project.websiteLink ? 1 : 0.4
                          }}
                          onClick={() => {
                            window.open(project.codeLink, '_blank');
                          }}>
                          <FaCode className='projectCodeLinkButtonIcon' />
                        </div>
                      </div>
                      <div className="projectWebsiteLinkButtonOuter">
                        <button className="projectWebsiteLinkButton"
                          style={{
                            pointerEvents: activeProject === index && project.websiteLink && projectSectionActive ? 'auto' : 'none',
                            opacity: project.websiteLink ? 1 : 0.4
                          }}
                          onClick={() => {
                            window.open(project.websiteLink, '_blank');
                          }}>
                          Website
                        </button>
                      </div>
                    </div>

                    <div className="projectNavButtons">
                      <button className="projectNavButtonLeft"
                        style={{
                          pointerEvents: activeProject === index && projectSectionActive ? 'auto' : 'none',
                        }}
                        onClick={() => {
                          if (!isMuted && (window.innerWidth > 600)) {
                            clickSound.currentTime = 0;
                            clickSound.play().catch(error => console.error("Error playing sound:", error));
                          } else if (!isMuted) {
                            clickSound.play().catch(error => console.error("Error playing sound:", error));
                          }
                          setActiveProject(activeProject === 0 ? 2 : activeProject - 1);
                        }}>
                        <FaArrowLeftLong className='projectNavButtonLeftIcon'
                          style={{
                            pointerEvents: activeProject === index && projectSectionActive ? 'auto' : 'none',
                          }}
                        />
                      </button>
                      <button className="projectNavButtonRight"
                        style={{
                          pointerEvents: activeProject === index && projectSectionActive ? 'auto' : 'none',
                        }}
                        onClick={() => {
                          if (!isMuted && (window.innerWidth > 600)) {
                            clickSound.currentTime = 0;
                            clickSound.play().catch(error => console.error("Error playing sound:", error));
                          } else if (!isMuted) {
                            clickSound.play().catch(error => console.error("Error playing sound:", error));
                          }
                          setActiveProject(activeProject === 2 ? 0 : activeProject + 1);
                        }}>
                        <FaArrowRightLong className='projectNavButtonRightIcon'
                          style={{
                            pointerEvents: activeProject === index && projectSectionActive ? 'auto' : 'none',
                          }}
                        />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>

          </div >
        </div >
      )
      }
    </div >
  );
};

export default MyHtml;