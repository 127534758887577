// store.js
import { create } from 'zustand';

const useStore = create(set => ({
  debugMode: false,
  setDebugMode: (debug) => set({ debugMode: debug }),
  muted: false,
  setMuted: (muted) => set({ muted: muted }),
  startButtonPressed: false,
  setStartButtonPressed: (pressed) => set(() => ({ startButtonPressed: pressed })),
  loadingProgress: 0,
  setLoadingProgress: (progress) => set(() => ({ loadingProgress: progress })),
  myInnerHtmlVisible: false,
  setMyInnerHtmlVisible: (visible) => set(() => ({ myInnerHtmlVisible: visible })),
  activeSection: 'Home',
  setActiveSection: (section) => set(() => ({ activeSection: section })),
  isMuted: false,
  setIsMuted: (muted) => set(() => ({ isMuted: muted })),
  orbitControlsEnabled: false,
  setOrbitControlsEnabled: (enabled) => set(() => ({ orbitControlsEnabled: enabled })),
  cameraFollowsCybertruck: false,
  setCameraFollowsCybertruck: (follows) => set(() => ({ cameraFollowsCybertruck: follows })),
  cameraLooksAtCybertruck: false,
  setCameraLooksAtCybertruck: (looks) => set(() => ({ cameraLooksAtCybertruck: looks })),
  mouseEffectsCamera: false,
  setMouseEffectsCamera: (effects) => set(() => ({ mouseEffectsCamera: effects })),
  freeCameraForDebug: false,
  setFreeCameraForDebug: (free) => set(() => ({ freeCameraForDebug: free })),
  cybertruckDrivingAnimationComplete: false,
  setCybertruckDrivingAnimationComplete: (complete) => set(() => ({ cybertruckDrivingAnimationComplete: complete })),
  useMergedIntroSound: true,
  setUseMergedIntroSound: (use) => set(() => ({ useMergedIntroSound: use })),
  projectSectionActive: false,
  setProjectSectionActive: (active) => set(() => ({ projectSectionActive: active })),
  lightMode: false,
  setLightMode: (light) => set(() => ({ lightMode: light })),
  dancing: true,
  setDancing: (dancing) => set(() => ({ dancing: dancing })),
}));

export default useStore;