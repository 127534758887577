import * as THREE from 'three'
import { Environment, OrbitControls, Sparkles } from '@react-three/drei'
import { Perf } from 'r3f-perf'
import { useEffect } from 'react'
import { useThree } from '@react-three/fiber'
import { useState } from 'react'
import gsap from 'gsap'

//
// Local imports
//
import { lightModeStyles, darkModeStyles } from './config.js';
import useStore from './store/store.js'
import Ground from './components/Ground.jsx'
import Cybertruck from './components/Cybertruck.jsx'
import TeslaBot from './components/TeslaBot.jsx'


export default function Experience() {
    //
    // Global states
    //
    const debugMode = useStore(state => state.debugMode)
    const orbitControlsEnabled = useStore(state => state.orbitControlsEnabled)
    const lightMode = useStore(state => state.lightMode)
    const projectSectionActive = useStore(state => state.projectSectionActive)

    //
    // Local states
    //
    const [sparklesSize, setSparklesSize] = useState(8);
    const [sparklesColor, setSparklesColor] = useState('#fff');
    const [previousProjectSectionActive, setPreviousProjectSectionActive] = useState(false);

    //
    // Refs
    //
    const { camera, scene } = useThree()

    //
    // On mount and resize -> update the FOV
    //
    useEffect(() => {
        const updateFov = () => {
            let newFov;
            if (window.innerWidth > 1200) {
                newFov = 45;
            } else if (window.innerWidth > 1000) {
                newFov = 50;
            } else if (window.innerWidth > 700) {
                newFov = 62;
            } else {
                newFov = 70;
                setSparklesSize(4);
            }
            camera.fov = newFov;
            camera.updateProjectionMatrix();
        };

        updateFov();
        window.addEventListener('resize', updateFov);
        // Cleanup
        return () => window.removeEventListener('resize', updateFov);
    }, []);

    //
    // On light mode change (and projectSection) and scene mount
    // 
    useEffect(() => {
        const currentStyles = lightMode ? lightModeStyles : darkModeStyles;

        const backgroundAndFogColor = currentStyles.backgroundAndFog;
        scene.background = new THREE.Color(backgroundAndFogColor);

        if (!scene.fog) {
            scene.fog = new THREE.FogExp2(backgroundAndFogColor, 0.06);
        }

        const targetDensity = projectSectionActive ? 0.01 : 0.06;
        if (projectSectionActive !== previousProjectSectionActive) {
            const durationOfFogChange = 1;
            gsap.to(scene.fog.color, {
                r: new THREE.Color(backgroundAndFogColor).r,
                g: new THREE.Color(backgroundAndFogColor).g,
                b: new THREE.Color(backgroundAndFogColor).b,
                duration: durationOfFogChange,
            });
            gsap.to(scene.fog, {
                density: targetDensity,
                duration: durationOfFogChange,
            });
        } else {
            scene.fog.color = new THREE.Color(backgroundAndFogColor);
            scene.fog.density = targetDensity;
        }

        setPreviousProjectSectionActive(projectSectionActive);

        if (projectSectionActive) {
            document.documentElement.style.setProperty('--default-text-color', darkModeStyles.defaultTextColor);
            document.documentElement.style.setProperty('--theme-color', darkModeStyles.themeColor);
            document.documentElement.style.setProperty('--project-button-border', darkModeStyles.projectButtonBorder);
        } else {
            document.documentElement.style.setProperty('--default-text-color', currentStyles.defaultTextColor);
            document.documentElement.style.setProperty('--theme-color', currentStyles.themeColor);
            document.documentElement.style.setProperty('--project-button-border', currentStyles.projectButtonBorder);
        }

        setSparklesColor(currentStyles.sparklesColor);
    }, [lightMode, scene, projectSectionActive]);

    return <>

        {/* Stats */}
        {debugMode && <Perf position="top-left" />}

        {/* Controls */}
        {orbitControlsEnabled &&
            <OrbitControls
                target={[-0.5, 0.8, 0]}
            />
        }
        <Environment files={'/hdrs/compressed_warm-diff.hdr'} />

        <Sparkles
            count={500}
            speed={1}
            size={sparklesSize}
            scale={20}
            color={sparklesColor}
        />
        <Sparkles
            count={500}
            speed={1}
            size={sparklesSize}
            scale={20}
            position={[-13.5, 0, 13.5]}
            color={sparklesColor}
        />
        <Sparkles
            count={500}
            speed={1}
            size={sparklesSize}
            scale={20}
            position={[-10, 0, 30]}
            color={sparklesColor}
        />

        {/* Main Objects */}
        <group position={[0, 0, 0]}>
            <Ground />
            <Cybertruck />
            <TeslaBot />
        </group >
    </>
}