import * as THREE from 'three';
import { useGLTF, useAnimations } from '@react-three/drei';
import { useEffect, useRef, useState } from 'react';
import useStore from '../store/store';


//
// Local imports
//

const modelPath = './models/TeslaBot.glb';

export default function Cybertruck() {
  //
  // Global states
  //
  const cybertruckDrivingAnimationComplete = useStore(state => state.cybertruckDrivingAnimationComplete);
  const dancing = useStore(state => state.dancing);
  const setDancing = useStore(state => state.setDancing);

  //
  // Local states
  //
  const model = useGLTF(modelPath);
  const animations = useAnimations(model.animations, model.scene);
  const [teslaIntroDanceComplete, setTeslaIntroDanceComplete] = useState(false);

  //
  // Refs
  //
  const teslaBotRef = useRef();
  const groupRef = useRef();
  const primitiveRef = useRef();
  const previousActionRef = useRef();

  //
  // On model ready -> set up the teslaBotRef
  //
  useEffect(() => {
    const teslaBotArmature = model.scene.getObjectByName('TeslaBotArmature');
    if (teslaBotArmature) {
      teslaBotArmature.scale.set(0, 0, 0);
      teslaBotRef.current = teslaBotArmature;
    } else {
      console.error("TeslaBotArmature not found in the scene.");
    }
  }, [model.scene]);

  //
  // On cybertruckDrivingAnimationComplete -> play the initial animations
  // 
  useEffect(() => {
    if (cybertruckDrivingAnimationComplete && teslaBotRef.current) {
      playAnimationsSequentially(['GetOutOfCar', 'Dance', 'Idle']).catch(error => console.error(error));
    }
  }, [cybertruckDrivingAnimationComplete]);

  //
  // On dancing -> transitio from idle to dance then after one play transition back to idle
  //
  useEffect(() => {
    if (dancing && teslaIntroDanceComplete) {
      playAnimationsSequentially(['WaveDanceAction', 'Idle']).catch(error => console.error(error));
    }
  }, [dancing]);

  function playAnimation(name, shouldLoop = false) {
    return new Promise((resolve, reject) => {
      const action = animations.actions[name];
      if (action) {
        action.setLoop(shouldLoop ? THREE.LoopRepeat : THREE.LoopOnce);
        action.clampWhenFinished = true;
        action.reset();
        let transitionTime;
        if (name === 'Dance') {
          transitionTime = 0.4;
        } else {
          transitionTime = 0.4;
        }
        if (name !== 'GetOutOfCar') {
          action.fadeIn(transitionTime);
        }

        action.setEffectiveTimeScale(1);

        action.play();
        if (previousActionRef.current) {
          previousActionRef.current.action.fadeOut(transitionTime);
        }

        previousActionRef.current = {
          name: name,
          action: action
        };

        teslaBotRef.current.scale.set(1, 1, 1);
        if (!shouldLoop) {
          const onAnimationFinished = () => {
            action.getMixer().removeEventListener('finished', onAnimationFinished);
            if (name === 'Dance') {
              setTimeout(() => {
                setTeslaIntroDanceComplete(true);
                setDancing(false);
              }, 650);
            }
            resolve(name);
          };

          action.getMixer().addEventListener('finished', onAnimationFinished, { once: true });
        } else {
          resolve(name);
        }
      } else {
        reject(new Error(`Animation "${name}" not found.`));
      }
    });
  }

  function playAnimationsSequentially(animationNames) {
    let promiseChain = Promise.resolve();
    animationNames.forEach((name, index) => {
      const isLastAnimation = index === animationNames.length - 1;
      promiseChain = promiseChain.then(() => playAnimation(name, isLastAnimation && name === 'Idle'));
    });
    return promiseChain;
  }

  return (
    <group ref={groupRef}>
      <primitive object={model.scene} ref={primitiveRef} />
    </group >
  );
}